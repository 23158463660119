.modal-notification-sucess-body{
    // background-color: #29d096;
    border-radius: 3px; 
    display: grid;
    text-align: center;
    align-items: center;
}

.modal-notification-data-circle-icon{
    font-size:13vh;
    color: #2c8ef8;
}

.modal-notification-data-circle-icon-danger{
    font-size:13vh;
    color: #fa5c7c;
}

.modal-notification-info{
    letter-spacing: 3px;
    font-size: 35px;
    color: #2c8ef8;
    font-weight: 300;

}


.modal-notification-danger{
    letter-spacing: 3px;
    font-size: 35px;
    color: #fa5c7c;
    font-weight: 300;

}

.modal-notification-info-text{
    font-size: 20px;
    margin-top: 15px;
    color: gray;
}

.confirm-info-button{
    width: 150px; 
    margin-top: 30px;
    margin-bottom: 5vh; 
}

.sucess-modal-info{
    width: 50% !important;
    height: 30% !important;
}