.taxes-bills{
    margin-top: 35px;
    margin-right: -11%;
    margin-left: 2%;
}

.taxes-bills-new{
    margin-top: 37px;
    margin-right: -11%;
    margin-left: 20%;
}
 
#submit-bills-to-pay-forms{
    display: none !important;
}

#verify-errors{
    display: none !important;
}

#reset-bills-to-pay-forms{
    display : none !important;
}


#reset-taxes-form{
    display : none !important;
}

.new-project{
    display : inline-flex;
    text-align: right !important;
    margin-left: auto;
    margin-right: 10px;
    float: right;
}

.project-label{
    margin-left : 12px;
}

.load-spiner-bils{
    position: absolute;
    left: 40%;
    top: 20%;
    justify-content: center;
    align-items: center;
}

.keep-checkbox{
    padding-left: 3%;
}

.simulatorButton{
    margin-left: 30%;
    margin-top: 5%;
    margin-bottom: 5%;
    width: 500px;
    height: 100px;
}

.tableButton{
    margin-right: 24px !important;
}


.simulationTable{
    .simulationTable .react-bootstrap-table {
        height: 50vh;
        max-height: 300px;
    }
}


.invalid-simulation-message{
    margin-top: 24px;
    background-color: rgba($color: #eef2f7, $alpha: 0.5);
    width: 100%;
    display: flex;
    text-align: center;
    padding-left: 30%;
    padding-top: 7%;
    padding-bottom: 7%;
}
.invalid-message{
    margin-top: 24px;
    background-color: rgba($color: #eef2f7, $alpha: 0.5);
    width: 100%;
    display: flex;
    text-align: center;
    padding-left: 40%;
    padding-top: 7%;
    padding-bottom: 7%;
}

.custom-status{
    margin-left:10px;
}

.register-button{
    margin-top: 10px;
}

.register-button-unique{
    margin-right: 10px;
}

.invalid-simulation-message-p{
    position: absolute;
    top: 20px;
    left: 20px;
}

.invalid-simulation-message button {
    width: 60%;
    height: 50px;
}