* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  background: #f1f3fa;
}

/*---------------------------------------------*/
.linksLogin {
  font-size: 16px;
  line-height: 1.7;
  color: #adb5bd !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.alterPassword {
  right: 12px;
  margin-bottom: -25px;
  position: absolute;
  cursor: pointer !important;
  z-index: 1000;
}

.limiter {
  width: 100%;
  margin: 0 auto;
}

.container-login100 {
  width: 100%;
  min-width: 500px !important;
  min-height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f1f3fa;
}

.wrap-login100 {
  width: 504px;
  min-height: 340px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  padding: 40px 40px 40px 40px;
}

.wrap-company {
  width: 100%;
  min-height: 340px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  padding: 40px 40px 40px 40px;
}

.company-form,
.button-divisor {
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}

.company-form h4 {
  color: #2c60d0;
}

.company-button {
  text-align: right;
}

.login100-form {
  width: 100%;
}

.login100-form-title {
  display: block;
  text-align: center;
  margin-bottom: 55px;
}

.container-login100-form-btn {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  // padding-top: 13px;
}

.regUser {
  margin-top: 5px;

  text-align: right !important;
}

.regUserMultiple {
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
}

.inputForm {
  margin-bottom: 20px !important;
}

.titleForm {
  color: #adb5bd;
}

.termoAceite {
  font-weight: bold;
  margin-left: -5px;
  text-align: center;
}

.term-accept-footer {
  position: absolute;
  bottom: 0px;
  width: 100%;
}

@media (max-height: 900px) {
  .term-accept-footer {
    position: inherit;
  }
}

.linkTermo {
  font-size: 16px;
  line-height: 1.7;
  color: #2c60d0 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

.alertPassword {
  color: #aab0b5;
  margin-top: 5px;
  font-weight: bold;
  font-size: 12px;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 600px) {
  .wrap-login100 {
    width: 400px;
  }

  .container-login100 {
    width: 100%;
    min-width: 400px !important;
    padding: 0px;
  }
}

@media (max-width: 360px) {
  .wrap-login100 {
    width: 300px;
  }

  .container-login100 {
    width: 100%;
    min-width: 300px !important;
    padding: 0px;
  }
}

.relevo-modal-confirm-header {
  font-weight: bold;
  font-size: x-large;
  text-align: center;
  color: #adb5bd;
  margin-top: 24px !important;
}

.relevo-modal-confirm-img {
  display: block;
  text-align: center;
  margin-bottom: 24px;
  margin-top: 24px !important;
}

.relevo-modal-confirm-subheader {
  font-weight: bold;
  text-align: center;
  margin-top: 24px !important;
}

.relevo-modal-confirm-text {
  text-align: center;
  margin-top: 24px !important;
}

.relevo-modal-confirm-text-blue {
  text-align: center;
  color: #2c60d0;
  margin-top: 24px !important;
}

.relevo-text-bold-modal {
  font-weight: bold;
}

.resend-modal-text-blue {
  color: #2c60d0;
  margin-top: 24px !important;
}
.relevo-modal-confirm-link {
  text-align: center !important;
  color: gray;
  margin-left: 30%;
  text-decoration: underline;
  margin-top: 24px !important;
}

.relevo-modal-confirm-footer {
  margin-left: 40% !important;
  margin-top: 24px !important;
}

.modal-confirm-mail-button {
  color: white !important;
  font-weight: bold;
  width: 100px !important;
}

.relevo-modal-confirm-img {
  align-items: center;
}

.eye-show-password {
  font-size: 20px;
  float: right;
  position: absolute;
  right: 25px;
  cursor: pointer;
  top: 33px;
}

.col-button-google button {
  width: 100% !important;
  background-color: #eef2f7 !important;
}

.col-button-google div {
  padding: none;
  text-align: right;
  width: 35%;
  background-color: #eef2f7 !important;
}

.col-button-google span {
  font-size: 1rem;
}

.col-button-facebook button {
  width: 100% !important;
  background-color: #1877f2 !important;
  color: #fff !important;
  border: none;
  border-radius: 0.25rem;
  min-height: 30px;
}

.div-button-facebook button {
  width: 100% !important;
  background-color: #1877f2 !important;
  color: #fff !important;
  border: none;
  border-radius: 0.25rem;
  min-height: 30px;
}

.col-button-facebook div {
  padding: none;
  text-align: right;
  width: 35%;
  background-color: #eef2f7 !important;
}

.col-button-faceboook span {
  font-size: 1rem;
}

.div-ou {
  padding-top: 15px;
  text-align: center;
}

.div-ou hr {
  margin-bottom: -0.6rem !important;
  border-top: 2px solid #eef2f7 !important;
}

.div-ou span {
  background-color: white !important;
  padding-left: 15px;
  padding-right: 15px;
  color: #464f5b !important;
}

.div-header-login {
  font-size: 25px;
  color: #464f5b !important;
  text-align: center;
}

// =================================== DOWNGRADE DE PLANO
.downgrade-span-title {
  color: #2c60d0;
  font-size: 40px;
  font-weight: bold;
}

.downgrade-wrapper {
  width: 60%;
  margin-top: 100px;
  padding-bottom: 0px;
  //width: 60vw;
}

@media (max-width: 900px) {
  .downgrade-wrapper {
    width: 85%;
  }
}

.downgrade-container {
  align-items: initial;
  margin-top: 50px;
  min-height: 85vh;
}

@media (max-height: 900px) {
  .downgrade-container {
    margin-top: 20px;
  }
}

.container-buttons-left {
  text-align: left;
  padding-top: 20px;
  a {
    margin-left: 10px;
    color: #fa5c7c;
  }

  a:hover {
    color: #e50633;
  }
}
.container-buttons-right {
  text-align: right;

  button {
    margin: 10px;
  }
}
@media (max-width: 580px) {
  .container-buttons-right,
  .container-buttons-left {
    text-align: center;

    button {
      width: 100%;
      margin-left: 0px;
      margin-right: 0px;
    }
  }
}

// =================================== END ===== DOWNGRADE DE PLANO
