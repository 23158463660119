.relevo-modal-confirm{
    border-top: none !important;
    border-bottom: none !important;

}

.relevo-modal-alert{
    width: 95%;
    margin-left: 2.5%;
}
