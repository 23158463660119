.payment-credi-card {
    height: 45px;
}

.payment-title {
    font-size: 26px;
    color: #2c60d0;
    font-weight: bolder;
}

.card-dashboard {
    width: 100% !important;
}

.payment-description {
    font-size: 12px;
    color: #2c60d0;
    font-weight: 400;
}

.gray-description {
    color: gray
}


.payment-form {
    width: 45%;
}

.free-test-description {
    text-align: center;
    width: 45%;
    margin-top: 1.25rem;
    font-size: 1.125rem;
    line-height: 1.25rem;
}

.payment-input-div {
    margin-top: 10px;
}

.box-plan {
    width: 48%;
    background-color: white;
    border: thin solid;
    border-color: #2c60d0;
    border-width: 2px;
    border-radius: 8px;
    padding: 15px;
    cursor: pointer;
    box-shadow: 0 0 1em rgba(0, 0, 0, 0.149);
}

.box-plan-active {
    border-color: #D9E1F5;
    background-color: #D9E1F5;
    box-shadow: none;
}

.payment-economy {
    color: rgb(6, 209, 6);
}

.payments-plans {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 10px;
}

.change-plan-page-section {
    margin-top: 10px;
    display: flex;
    justify-content: center;
}

.change-plan-page-link {
    font-size: 16px;
    line-height: 1.7;
    color: #adb5bd !important;
    text-decoration: underline !important;
    cursor: pointer !important;
}

.box-plan-title {
    font-size: 17px;
    font-weight: 800;
    color: #2c60d0;
}

.box-plan-description {
    font-size: 12px;
    font-weight: bold;
    color: #2c60d0;
}

.payment-divider {
    height: 1px;
    background-color: #dfe3e9;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 10px;
}

.payment-button {
    width: 100% !important;
    margin-top: 10px;
    height: 50px;
}

.safe-payment-box{
    background-color: aquamarine;
    border-radius: 5px;
}

.payment-disclamer {

    text-align: center;
    font-size: 11px;
    width: 100%;
    font-weight: lighter;
}

.payment-disclamer-bold {
    text-align: center;
    font-size: 11px;
    margin-top: 8px;
    font-weight: bolder !important;
    width: 100%;
}

.payment-test-free-badge {
    font-size: 1rem;

    text-align: center;
    font-weight: 400;
    color: #ffffff;
    background-color: #005193;
    width: 100%;
    padding: 0.5rem 1.25rem;
    text-wrap: pretty;
    // height: 28px;
}

// @media (min-width: 900px) {
//     .payment-test-free-badge {
//         padding: 0 1.25rem;
//     }
// }

.img-payment {
    margin-top: 10%;
    margin-left: 15%;
}

.payment-disclamer-box {
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
}

.error-data {
    color: red;
    font-size: 13px;
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.text-centered {
    text-align: center;
}

.payment-title-two {
    font-size: 23px;
    font-weight: bold;
    color: #2c60d0;
    margin-top: 30px;
}

.payment-login {
    margin-top: 20px;
}

.payment-button-black {
    width: 100% !important;
    margin-top: 10px;
    height: 50px;
    background: #313a47;
    border-color: #313a47;
    color: white;
    margin-top: 30px;
    margin-bottom: 50px;
}

.payment-button-black:hover {
    color: rgba(255, 255, 255, 0.336);
    background-color: #313a47;
}

.image-mobile-marca {
    display: none;
}

@media only screen and (max-width: 800px) {
    .payment-form {
        width: 80%;
    }

    .image-mobile-marca {
        display: block;
    }

    .free-test-description {
        width: 80%;
    }
}