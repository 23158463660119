.k-textbox:disabled,
.k-textbox[disabled],
.k-textbox.k-state-disabled {
  background-color: #eaecef !important;
  border-color: #ced4da !important;
  opacity: initial !important;
  color: #495057;
}
.k-label .k-text-disabled {
  color: #495057 !important;
}

.k-text-disabled {
  opacity: initial !important;
}

.requiredValidator {
  color: #fa5c7c;
}

.not-validated-field {
  color: #fa5c7c;
  font-size: 12px;
}

.validated-field {
  color: #0acf97;
  font-size: 12px;
}

.none-validated-field {
  font-size: 12px;
}
