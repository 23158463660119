.contact-forms{
    margin-top: 24px !important;
}

.contact-forms-row{
    margin-top: 24px;
}

.generateInvoice_col{
    margin-top: 35px;
    margin-right: -11%;
    margin-left: 6%;
}

.block_client{
    margin-left: 2%;
}

.simples-nacional{
    margin-top: 35px;
    margin-right: -11%;
    margin-left: 6%;
}

input#produtor_rural {
    bottom: 13px;
}

.simples-nacional {
    margin-top: 25px;
    margin-right: -18%;
    margin-left: 3%;
    display: inline-grid;
}