* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  background: #f1f3fa;
}

.apresentation {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  min-height: 40vh;
}

.apresentation span {
  color: #2c60d0;
  font-size: 60px;
  font-weight: bold;
}

.paragraph1 {
  color: #3264d1;
  font-size: 20px;
  font-weight: 600;
  margin-top: 20px;
}

.paragraph2 {
  color: #6c757d;
  font-size: 16px;
  font-weight: bold;

  margin-bottom: 30px;
}

/*------------------------------------------------------------------
[ Responsive ]*/

@media (max-width: 360px) {
  .apresentation span {
    font-size: 30px;
  }

  .paragraph1 {
    font-size: 15px;
  }

  .paragraph2 {
    font-size: 13px;
  }
}

.title-main{
  font-size: 30px !important;
}


