.actionsTreeTable{
    color: #98A6AD !important;
    text-align: left !important;
}

.columnActions{
    text-align: right !important;
}

html .k-grid tr:hover {
    background: #fafafa !important;
  }
   