.payment-patern{
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 100%;
}

.payment-presentation{
    background: #2c60d0;
    height: 100vh;
    display: inline-flex;
    flex-direction:column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 40%;

    height: auto;
    min-height: 100vh;
}
 

.payment-content{
    width: 60%;
    display: inline-flex;
    flex-direction:column;
    align-items: center;
    align-content: center;
    justify-content: center;
}

.payment-logo{
    width: 100%;
    height: 50px;
    object-fit: contain;
    margin-bottom: 50px;
}

.payment-logo-git{
    width: 250px;
    height: 270px;
    margin-top: 30px;
}

.payment-text-one{
    margin-top: 20px;
    font-size: 40px;
    text-align: center;
    color: white;
    font-weight: bold;
    line-height: 40px;
    letter-spacing: 4px;
    cursor:default;
}

.payment-logo-description{
    margin-top: 20px;
    text-align: center;
    color: #D4DDF4;
    font-size: 16px;
    font-weight: 100;
    cursor:default;
}

.payment-logo-termos{
    color: #D4DDF4;
    font-size: 11px;
    margin-top: 25px;
    text-align: center;
}

.underline:hover{
    color: #d4ddf48d;
}

.underline{
    text-decoration: underline;
}


@media only screen and (max-width: 900px) {
    
    .payment-presentation{
      display: none;
    }

    .payment-presentation-black{
        display: none !important;
    }
     
    .payment-content{
        width: 100%;
        margin-top: 20px;
        margin-bottom: 40px;
    }
}

.payment-black-friday{
    width: 80%;
}

.payment-presentation-black{
    background: #313a47;
    height: 100vh;
    display: inline-flex;
    flex-direction:column;
    align-items: center;
    align-content: center;
    justify-content: center;
    width: 40%;
}