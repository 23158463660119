.actionsGrid{
    color: #98A6AD !important;
    text-align: left !important;
}

.icons-bigger span{
    font-size: 20px;
}

.columnActions{
    text-align: right !important;
}

html .k-grid tr:hover {
    background: #fafafa !important;
  }
.k-widget .k-grid .k-header{
    color: #6c757d ;
}