
.relevo-invalid-class{
    outline:#fa5c7c !important;
    border-color: #fa5c7c !important;
    padding-right: 2.25rem;
    background-image: url("../../../../images/relevo-components/error.svg");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}


.relevo-react-select__control{
    border-color: #fa5c7c !important;
}