//
// general.scss
//

html {
  position: relative;
  min-height: 100%;
}

body {
  overflow-x: hidden;
}

// Fix event bubbling on iOS devices
@supports (-webkit-overflow-scrolling: touch) {
  body {
    cursor: pointer;
  }
}
.white {
  background-color: white;
}

.inputForm {
  margin-bottom: 26px;
}

.badge-default {
  height: 27px;
  padding: 9px;
  font-size: 13px;
  margin-top: 3px;
  margin-bottom: 3px;
}

.relevo-badge-upgrade {
  cursor: pointer;
  color: "#fff";
}

.relevo-badge-upgrade:hover {
  background-color: #fa5c7c;
  color: #fff !important;
}

@media (max-width: 1029px) {
  .link-toggle-sidebar {
    display: none;
  }
}

.noti-icon-badge {
  right: auto !important;
  margin-left: 37px !important;
  z-index: 2 !important;
}

.notification-item {
  position: relative !important;
  margin-left: 8px !important;
  top: 20px !important;
  background-color: #3162cd !important;
}

.notification-item-tips {
  float: right;
  display: inline-block;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: #3162cd !important;
  margin-top: 7px;
  margin-right: 10px;
}

.link-upper-label {
  display: inline-flex;
  text-align: right !important;
  margin-left: auto;
  margin-right: 10px;
  float: right;
}

.popover.bs-popover-auto {
  z-index: 99999 !important;
}

.icon-info-in-text {
  position: relative;
  top: 2px;
  font-size: 20px;
  margin-left: 10px;
}

.icon-info-in-text-absolute {
  position: absolute;
  top: -5px;
}

.button-grey-text-blue {
  border-color: #d5e0f9 !important;
  color: #2c60d0 !important;
  background-color: #d5e0f9 !important;
  font-weight: 600 !important;
}

.button-grey-text-blue:hover {
  background-color: #c2cee8 !important;
}

.pattern-feedback {
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.75rem;
  color: #fa5c7c;
}

.div-align-items-center {
  text-align: center;
}
.container-config-tickets {
  .text-18px {
    //font-size: 18px;
    font-weight: 500;
  }
  .row {
    margin-top: 30px;
  }
}


#pendo-close-guide-93f3ba8a{
  color: black !important;
}