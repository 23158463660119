.btn-datepicker-barlateral {
    background-color: #F1F3FA!important;
    border: 0px!important;
    // border-color: #F1F3FA!important;
    .k-icon {
        font-size: x-large;
        color: $gray-500!important;
    }
}

.custom-datePicker-barlateral input{
    color: #2c60d0!important;
    background-color: #F1F3FA!important;
    border: 0px!important;
    font-weight: 600;
    text-align: center;
    text-transform: capitalize;
}

.custom-datePicker-barlateral .k-state-disabled {
    opacity: 1!important;
    filter: none!important;
    border-color: #F1F3FA!important;
}

.custom-datePicker-barlateral {
    span.k-picker-wrap.k-state-default.k-picker-wrap{
        border: 0px!important;
    }

    button.k-button.k-select {
        border: 0px;
    }
}
