.suppliersTable .react-bootstrap-table {
    height: 50vh;
    max-height: 450px;
}

.accountsTable .react-bootstrap-table {
    height: 50vh;
    max-height: 450px;
}

.toogleClass.dropdown-menu.show {
    overflow-y: auto;
    height: 57vh;
    //top: 207px !important;
}