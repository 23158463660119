.icon-check {
  font-size: 65px !important;
  color: #36cf97;
}

.icon-warning {
  font-size: 65px !important;
  color: #ffbb40;
}

.span-success {
  color: #36cf97;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3px;
}

.span-text {
  font-weight: 600;
}

.span-error {
  color: #ffbb40;
  font-size: 18px;
  font-weight: 700;
  letter-spacing: 3px;
}

.progress-bar-reload{
  background: #36cf97;
}


.k-progressbar-horizontal{
  height: 3px !important;
}