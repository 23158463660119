
.contacts-forms-row{
    margin-top: 24px !important; 
}
.btn_relevo{
    background: #4281C5 !important;
    color: white; 
}

.required-field{
    color:red !important
}

#submit-customers-supliers-forms{
    display:none
}
#submit-customers-supliers-forms-reset{
    display:none

}
#submit-account-forms{
    display:none
}

#submit-account-forms-reset{
    display:none !important;
}
#submit-account-other-forms-reset{
    display:none !important;

}

#clear-cnae{
    display: none !important;
}

.custom-feedback{
    width: 100%;
    margin-top: 0.25rem;
    font-size: 0.75rem;
    color: #fa5c7c
}
.custom-feedback-off{
    display:none !important;

}
.custom-required-border{
    border-color: #fa5c7c
}


.fixDanger{
    color:#6c757d !important
}
.loading-div{
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: white;
    opacity: 0.4;
    z-index: 99999;
}
.loading-div-modal{
    position: absolute;
    width: 98%;
    height: 100%;
    background-color: white;
    opacity: 0.4;
    z-index: 99999;
}

.load-spiner{
    position: absolute;
    left: 50%;
    top: 40%;
    justify-content: center;
    align-items: center;
}



.modalNewInvoice{
    width: 100%;
    height: 50px;
}

#buttonNewInvoice{
    position: absolute;
    right: 10px;
}

.modalNewService{
    width: 100%;
    height: 50px;
}

#buttonNewService{
    position: absolute;
    right: 10px;
}