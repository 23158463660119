.carousel-control-next-icon,
.carousel-control-prev-icon {
  color: #2c60d0;
  font-size: 30px;

  background-image: none !important;
  top: -35px;
  position: relative;
}

.container-content-carousel {
  width: 100%;
  height: 250px;
  background: rgb(255, 255, 255);
  text-align: center;
}

.actual-step {
  font-weight: bold;
  font-size: 20px;
  margin-top: 20px;
}

.container-icon {
  width: 50px;
  height: 50px;
  margin-top: 30px;
  border-radius: 50%;
  padding-top: 5px;
  font-size: 25px;
  background: #d5e0f6;
  color: #2c60d0;
  display: inline-block;
  margin-left: 10px;
  margin-right: 10px;
}

.title {
  margin-top: 15px;
  font-weight: bolder;
  color: #2c60d0;
  font-size: 18px;
}

.text {
  margin-top: 20px;
  font-weight: 500;
}

.container-carousel {
  border-radius: 30px;
}

.image-header {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.carousel-inner {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.welcome-user {
  font-weight: bold;
  font-size: 18px;
}
.welcome-user,
.text-user {
  margin-left: 30px;
  padding-top: 20px;
}
