.k-dropdown .k-dropdown-wrap, .k-dropdowntree .k-dropdown-wrap {
  border-color: #dee2e6;
  color: #212529;
  background-color: #fff !important;
}

.k-dropdown .k-dropdown-wrap:hover, .k-dropdowntree .k-dropdown-wrap:hover, .k-dropdown .k-dropdown-wrap.k-state-hover, .k-dropdowntree .k-dropdown-wrap.k-state-hover {
  border-color: #c1c9d0;
  color: #212529;
  background-color: #fff !important;
}

.k-popup .k-child-animation-container .k-slide-down-enter .k-slide-down-enter-active{


}

.k-treeview {
  max-height: 250px !important;
 
 
}




