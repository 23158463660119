.pay-icon{
    font-size: 20px;
}

.credit-card{
    display: flex;
    flex-direction: column; 
}

.credit-card-payment{
    margin-top: 20px;
}

.delete_plan{
    color: red;

}

.delete_plan:hover{
    color: rgba(255, 0, 0, 0.491);
}

.main-pay{
    width: 100%;
    display: grid;
    justify-content: space-between;
    margin-bottom: 20px;
}


.payment-cancel-moldal-text-one{
    color:red;
}

.error-data{
    color:red;
    margin-top: 10px;
}

.payment-cancel-moldal-text-two{
    font-size: 13px;
    margin-top: 10px;
}

.payment-renewn-moldal-text-one{
    color: #2c60d0;
}

.alterar-credit-card{
    margin-left: 10px;
}
 
.relevo-modal-footer{
    margin-top: 20px;
    float:right
}


.payment-inputs-data{
    margin-top: 10px;
}

.plans-list{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
}

.plan-data{
    width:60%;  
    margin-left: auto;
    margin-right: auto;
}

.payment-divider{
    width: 100%;
    height:1px;
    background-color:rgba(128, 128, 128, 0.384); 
    margin-bottom: 10px;
}

@media only screen and (max-width: 850px) {
    .plans-list{
        display: grid; 
    }
}

.container-sidebar-tabs .nav-item{
    background: #f1f3fa !important;
}

.container-sidebar-tabs .active.nav-link{
    // background: #ebe9e9 !important;
    background: #f1f3fa !important;
    filter: brightness(85%);
    color: #2c60d0 !important;
}


.plans-nav{
    width: 40% !important;
    margin-left: 30% !important;
}

@media only screen and (max-width: 1537px){
    .plans-nav{
        width: 70% !important;
        margin-left: 15% !important;
    }
}