
.k-button.k-state-selected
{
    background-color: #2c60d0 !important;
    border-color: #2c60d0 !important;
}

.k-widget.k-scheduler.k-scheduler-flex.k-floatwrap
{
    height: 80vh !important;
}