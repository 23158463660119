.modal-message-user{
    max-width: 600px !important;
    top: 20%;
    
    .modal-content{
        border-radius: 1.2rem !important;
    }
}

    .modal-message-user .modal-header{
        padding: 0 !important;
    }

.img-header-modal{
    border-radius: 1.2rem 1.2rem 0rem 0rem;
}