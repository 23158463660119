.popover-header {
  background: #2c60d0 !important;
  color: #fff;
}

.popover {
  border: none !important;
}

.popover-icon-close {
  color: #fff;
  position: absolute;
}

.popover.show.bs-popover-auto {
  box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.2) !important;
}
