.open-modal-support {
  background-color: transparent;
  background: rgba(0,0,0,0.5);
  color: white;
  padding: 16px 20px;
  border: none;
  cursor: pointer;
  opacity: 0.8;
  position: fixed;
  bottom: 40px;
  right: 28px;
  width: 75px;
  height: 75px;
  border-radius: 50%;
  font-size: 30px;
}



.modal-pendo-simplific{
  background-color: transparent;
  // background: #2c8ef8;
  color: white;
  border: none;
  cursor: pointer;
  position: fixed;
  bottom: 40px;
  right: 28px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 30px;
}



.blink_me {
  animation: blinker 2s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}


