.steps {
    margin: 5px;
    margin-top: 20px;
}

.step-button:hover{
    background-color: #08ab7c;
    border-color: #089e74;
    cursor: pointer;
}

.step-button {
    margin-bottom: 20px;
    width: 28vw;
    height: 5vw;
    min-width: 290px;
    min-height: 80px;
    max-width: 400px;
    max-height: 80px;
    background-color: $finances;
    border-color: transparent;
}

.step-container-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.step-title{
    margin-right: 10px;
    font-size: $h1-font-size;
    color: $white;
}
.step-text{
    font-family: 'Nunito Bold';
    color: $white;
}