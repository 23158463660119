* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  background-color: #f1f3fa !important;
}

.limiter {
  width: 100%;
  margin: 0 auto;
  background: #f1f3fa;
}

.container-company {
  width: 100%;
  // min-width: 500px !important;
  min-height: 85vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: #f1f3fa;
}

.wrap-company {
  width: 100%;
  min-height: 450px;
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  padding: 40px 40px 40px 40px;
}

.company-form,
.button-divisor {
  width: 80%;
  margin-top: 30px;
  margin-left: auto;
  margin-right: auto;
}
@media (max-width: 1300px) {
  .company-form,
  .button-divisor {
    width: 100% !important;
  }
}

.company-form h4 {
  color: #2c60d0;
}

.company-button {
  text-align: right;
}

.inputForm {
  margin-bottom: 26px;
}

.passo {
  margin-top: -40px;
}

.textFooter {
  text-align: center;
  padding-bottom: 8px !important;
}

.linkTermo {
  font-size: 16px;
  line-height: 1.7;
  color: #2c60d0 !important;
  text-decoration: underline !important;
  cursor: pointer !important;
}

/*------------------------------------------------------------------
  [ Responsive ]*/

@media (max-width: 600px) {
  .wrap-company {
    width: 400px;
  }

  .container-company {
    width: 100%;
    min-width: 400px !important;
    padding: 0px;
  }

  .k-state-default {
    font-size: 12px !important;
  }
}

@media (max-width: 360px) {
  .wrap-company {
    width: 300px;
  }

  .container-company {
    width: 100%;
    min-width: 300px !important;
    padding: 0px;
  }

  .k-state-default {
    font-size: 10px !important;
  }

  .passo h5 {
    font-size: 12px !important;
    margin-top: 50px;
  }
}

.container-butons {
  display: inline-block;
  text-align: right;
  width: 100%;
}

// .container-icon-close {
//   position: absolute;
//   right: 10px;
// }
// .icon-close-modal {
//   font-size: 30px;
//   font-weight: 800;
//   cursor: pointer;
// }



.button-close {
  position: absolute;
  right: 15px;
  top: 15px;
  width: 32px;
  height: 32px;
  opacity: 0.3;
  z-index: 99999;
}
.button-close:hover {
  opacity: 0.5;
}
.button-close:before, .button-close:after {
  position: absolute;
  left: 15px;
  content: ' ';
  height: 33px;
  width: 2px;
  background-color: #333;
}
.button-close:before {
  transform: rotate(45deg);
}
.button-close:after {
  transform: rotate(-45deg);
}

.button-divisor{
  height: 2px;
  background-color: #e9ecef
}