.modal-promotion-banner:first-child {
  width: fit-content;
  border: none;
  padding: 7px;
  color: #fff;
  font-weight: 600;
  background-image: linear-gradient(to right, #36cf97, #39afd1);
  letter-spacing: 0.5px;
}

.modal-promotion-button-check-plans {
  border: none;
  background: transparent;
}

.modal-promotion-button-check-plans{
  background-color: initial;
  border-color: initial;
}
.modal-promotion-button-check-plans:first-child {
  margin-top: 20px;
  padding: 10px;
  border: none;
  background: rgb(44, 96, 208);
  color: rgb(255, 255, 255);
  border-radius: 5px;
}

.modal-promotion-button-check-plans:first-child:hover {
  background-color: #2551b0;
  border-color: #234da6;
}
